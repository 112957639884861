.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dashboard-title {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  