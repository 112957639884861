/* General container styling */
.task-manager-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 50px auto;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

/* Heading styling */
.task-manager-heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

/* Button group styling */
.button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.task-button {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.task-button:hover {
    background-color: #0056b3;
}

.task-button.active {
    background-color: #0056b3;
}

.task-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .task-manager-container {
        max-width: 100%;
        padding: 15px;
    }

    .task-manager-heading {
        font-size: 1.5rem;
    }

    .task-button {
        font-size: 0.9rem;
    }
}
