.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .auth-form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 400px;
  }
  
  .auth-form h1 {
    margin-bottom: 1.5rem;
    color: #2d2d2d;
  }
  
  .form-group {
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #2d2d2d;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .auth-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #0056d6;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: #003d99;
  }
  
  .auth-toggle {
    margin-top: 1rem;
    color: #6c6c6c;
  }
  
  .auth-toggle span {
    color: #0056d6;
    cursor: pointer;
    text-decoration: underline;
  }

  .back-button {
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: #e0e0e0;
    color: #2d2d2d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #c0c0c0;
  }

  /* AuthPage.css */
.oauth-buttons {
  margin: 20px 0;
  text-align: center;
}

.google-btn {
  display: inline-block;
  background: #4285f4;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s;
}

.google-btn:hover {
  background: #357abd;
}