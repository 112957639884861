/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #2d2d2d;
  }
  
  /* Header Styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2d2d2d;
  }
  
  .nav-links a {
    margin: 0 1rem;
    text-decoration: none;
    color: #2d2d2d;
    font-weight: 500;
  }
  
  .nav-links a:hover {
    color: #0056d6;
  }
  
  /* Hero Section Styles */
  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .hero-text {
    max-width: 600px;
    margin-bottom: 2rem;
  }
  
  .hero-text h1 {
    font-size: 2.5rem;
    color: #2d2d2d;
  }
  
  .hero-text p {
    margin: 1rem 0;
    color: #6c6c6c;
    font-size: 1.2rem;
  }
  
  .cta-button {
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: #0056d6;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #003d99;
  }
  
  /* Hero Image */
  .hero-image img {
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  @media (min-width: 768px) {
    .hero {
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 4rem;
      text-align: left;
    }
  
    .hero-text {
      max-width: 50%;
    }
  
    .hero-image img {
      width: 400px;
    }
  }
  