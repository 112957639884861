.new-pr-container {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
}

.new-pr-heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    display: block;
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 5px;
}

.form-select, .form-input, .form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
}

.form-select:focus, .form-input:focus, .form-textarea:focus {
    border-color: #007bff;
}

.form-textarea {
    min-height: 100px;
    resize: vertical;
}

.submit-btn, .loading-btn {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.loading-btn {
    background-color: #ccc;
    cursor: not-allowed;
}