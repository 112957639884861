/* General container styling */
.repo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 50px auto;
}

/* Heading styling */
.repo-heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* Select dropdown styling */
.repo-select-box {
    width: 100%;
}

.repo-select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
    background-color: #fff;
    color: #333;
}

.repo-select:hover, .repo-select:focus {
    border-color: #007bff;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .repo-container {
        max-width: 100%;
        padding: 15px;
    }

    .repo-heading {
        font-size: 1.5rem;
    }
}
