.update-pr-container {
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
}

.update-pr-heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    display: block;
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 8px;
}

.form-select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    outline: none;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form-select:focus {
    border-color: #007bff;
}

.suggestions-heading {
    font-size: 1.4rem;
    margin-top: 20px;
    color: #444;
}

.suggestions-list {
    list-style: none;
    padding-left: 0;
}

.suggestion-item {
    background-color: #e9ecef;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.no-suggestions {
    color: #888;
}

.task-prompt-heading {
    font-size: 1.4rem;
    margin-top: 20px;
    color: #444;
}

.task-prompt-text {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    color: #333;
}

.task-textarea {
    width: 100%;
    min-height: 80px; /* Changed to min-height for flexibility */
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    resize: vertical; /* Allows vertical resizing */
    outline: none;
}

.submit-btn, .loading-btn {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1.1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.loading-btn {
    background-color: #ccc;
    cursor: not-allowed;
}

.section-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
  }
  
  .status-badge {
    padding: 0.3rem 0.8rem;
    border-radius: 15px;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  .status-badge.generated {
    background-color: #4CAF50;
    color: white;
  }
  
  .status-badge.pending {
    background-color: #ff9800;
    color: white;
  }
  
  .formatted-content {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
  }
  
  .suggestion-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .suggestion-header {
    margin-bottom: 0.5rem;
    color: #666;
  }
  
  .file-info {
    font-size: 0.9rem;
  }
  
  code {
    background: #e0e0e0;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: monospace;
  }
  
  strong {
    font-weight: 600;
    color: #333;
  }
  
  .no-content {
    color: #888;
    font-style: italic;
    text-align: center;
    padding: 1rem;
  }

  /* Task Options */
.task-options {
    margin: 1rem 0;
    padding: 0.5rem;
    background: #f8f8f8;
    border-radius: 4px;
}

.toggle-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.toggle-label input {
    margin: 0;
    width: 1.1rem;
    height: 1.1rem;
}